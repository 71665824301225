<script>
export default {
    name: 'CreateAccount',
    data() {
        return {
            telegramId: null,
            invitedBy: null,
            errorMessage: '',
        }
    },
    methods: {
        async createAccount() {
            try {
                const response = await fetch('https://api.splitly.app:3000/user/create', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        telegramId: this.telegramId,
                        invitedBy: this.invitedBy
                    }),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    this.errorMessage = errorData.error || 'Произошла ошибка';
                    console.error('Ошибка при создании аккаунта:', this.errorMessage);
                    return;
                }

                const data = await response.json();
                console.log('Аккаунт успешно создан:', data.message);
                // Здесь можно выполнить дополнительные действия, например, перенаправить пользователя
            } catch (error) {
                this.errorMessage = 'Сетевая ошибка';
                console.error('Ошибка:', error);
            }
        }
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        this.invitedBy = urlParams.get('tgWebAppStartParam')?.replace('ref_', '') || ''; // Получаем реферера
        console.log('Referrer ID:', this.invitedBy);

        if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.ready();
            this.isTelegramLoaded = true;
            const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
            this.telegramId = initDataUnsafe.user?.id || 1; // Используем 1 как заглушку по умолчанию
            console.log('Telegram ID:', this.telegramId);
        }
    }
}
</script>



<template>
    <div class="create-account">
        <section class="logo">
            <img src="../assets/logo.svg" class="logo-icon" alt="Logo">
        </section>
        <section class="hello-text">
            <div class="hello-main-text">Место, где половинки становятся целыми</div>
            <div class="hello-description">
                Знакомства, общение, поиск друзей или партнера, все это мы постарались уместить тут
            </div>
        </section>
        <section class="start-button-section">
            <div class="start_button" @click="createAccount">
                Создать аккаунт
            </div>
        </section>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
</template>

<style scoped>
    .create-account {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url(../assets/images/create-account.png);

        height: 100%;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hello-text {
        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: column;
    }

    .hello-main-text {
        font-weight: 600;
        line-height: 115%;
        letter-spacing: -0.03em;
        color: #000;
    }

    .hello-description {
        color: #404040;
    }

    .start-button-section {
        position: fixed;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .start_button {
        background: #000e08;
        color: #fff;

        font-weight: 500;

        display: flex;
        align-items: center;
        justify-content: center;
    }

@media (min-width: 360px) {
    .logo {
        width: 100%;
        height: 97px;
    }

    .logo-icon {
        height: 37px;
        width: 138px;
    }

    .hello-text {
        width: 100%;
        height: 384px;

        gap: 18px;
    }

    .hello-main-text {
        font-size: 60px;
        line-height: 120%;
        width: 313px;
    }

    .hello-description {
        font-size: 15px;
        line-height: 173%;

        width: 313px;
    }

    .start-button-section {
        bottom: 33px;
    }

    .start_button {
        height: 56px;
        width: 314px;

        border-radius: 20px;
        font-size: 18px;

        bottom: 23px;
    }
}
</style>